import i18n from '@/config/i18n'

const account = process.env.VUE_APP_ACCOUNT_LINK

const redirectTo = function ({ token, refresh = false }) {
  const query = JSON.parse(localStorage.getItem('query') || '{}')
  const redirect = query.redirect || account
  var path = ''

  const findQuery = redirect.indexOf('?') !== -1
  if (!findQuery) {
    path = `${redirect}?refresh=${refresh}&token=${token}`
  } else {
    path = `${redirect}&refresh=${refresh}&token=${token}`
  }

  window.location.replace(path)
}

export default {
  install: ({ config: { globalProperties } }) => {
    const { $store, $router } = globalProperties

    globalProperties.$auth = {
      init: async () => {
        await $router.isReady()

        const locale = $router.currentRoute?._value?.params?.locale || 'en'
        i18n.global.locale = locale
      },
      redirect: async ({ token, isRefresh = false }) => {
        $store.commit('PRELOADER_GLOBAL_EDIT', true)
        redirectTo({ token, isRefresh })
      }
    }

    globalProperties.$auth.init()
  }
}
