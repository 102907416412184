<template>
  <div
    class="locale"
    v-click-outside="hide"
  >
    <button
      class="locale__button"
      :class="{
        active: active
      }"
      @click="active = !active"
    >
      <svg
        class="icon"
        width="14" height="14"
        style="min-width: 14px; min-height: 14px;"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 14C10.8543 14 14 10.8541 14 7C14 3.14562 10.8541 0 7 0C3.14565 0 0 3.14587 0 7C0 10.8544 3.14587 14 7 14ZM9.07668 12.8203C9.4733 12.3135 9.7773 11.709 10.0057 11.1013H11.618C10.9348 11.8699 10.0634 12.4672 9.07668 12.8203ZM12.2342 10.2812H10.2708C10.5129 9.40111 10.6549 8.42527 10.6851 7.41016H13.1657C13.0966 8.45983 12.7643 9.43865 12.2342 10.2812ZM12.2342 3.71875C12.7643 4.56135 13.0966 5.54017 13.1657 6.58984H10.6851C10.6549 5.57473 10.5129 4.59889 10.2708 3.71875H12.2342ZM11.618 2.89844H10.0056C9.77736 2.29127 9.47338 1.68667 9.07665 1.17969C10.0634 1.53284 10.9348 2.13008 11.618 2.89844ZM7.41016 0.890477C8.21505 1.16329 8.79247 2.12054 9.12272 2.89844H7.41016V0.890477ZM7.41016 3.71875H9.41793C9.67873 4.58221 9.83216 5.5618 9.86445 6.58984H7.41016V3.71875ZM7.41016 7.40988H9.86445C9.83216 8.4382 9.67873 9.41779 9.41793 10.2812H7.41016V7.40988ZM7.41016 11.1013H9.12272C8.79192 11.8808 8.21439 12.837 7.41016 13.1095V11.1013ZM2.38202 11.1016H3.99437C4.22264 11.7087 4.52662 12.3133 4.92335 12.8203C3.9366 12.4672 3.06521 11.8699 2.38202 11.1016ZM6.58984 13.1095C5.78564 12.837 5.20814 11.8809 4.87728 11.1016H6.58984V13.1095ZM6.58984 10.2812H4.58207C4.32127 9.41779 4.16784 8.4382 4.13555 7.41016H6.58984V10.2812ZM6.58984 6.58984H4.13555C4.16784 5.5618 4.32127 4.58221 4.58207 3.71875H6.58984V6.58984ZM6.58984 0.890477V2.89844H4.87728C5.20811 2.1192 5.78558 1.16307 6.58984 0.890477ZM4.92332 1.17969C4.5267 1.68651 4.22272 2.291 3.99435 2.89844H2.38202C3.06521 2.13008 3.9366 1.53281 4.92332 1.17969ZM1.76578 3.71848H3.72917C3.48712 4.59889 3.34512 5.57473 3.31494 6.58984H0.834258C0.903383 5.54017 1.23566 4.56135 1.76578 3.71848ZM0.834258 7.41016H3.31494C3.34512 8.42527 3.48712 9.40111 3.72917 10.2812H1.76578C1.23566 9.43865 0.903383 8.45983 0.834258 7.41016Z" fill="#787C8D"/>
      </svg>

      {{ _activeLocale() }}

      <svg
        class="arrow-icon"
        width="10" height="6"
        style="min-width: 10px; min-height: 6px;"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 1L5 5L1 1" stroke="#787C8D" stroke-linecap="round"/>
      </svg>
    </button>

    <div
      v-if="active"
      class="locale__list"
    >
      <button
        v-for="locale in locales"
        :key="locale.code"
        class="locale__list__item"
        :class="{
          active : $i18n.locale === locale.code
        }"
        @click="
          $root.$i18n.locale = locale.code,
          setLocale(locale.code),
          hide()
        "
      >
        {{ locale.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: false,
      locales: [
        {
          name: 'Eng',
          code: 'en'
        },
        {
          name: 'Рус',
          code: 'ru'
        },
        {
          name: 'Укр',
          code: 'uk'
        }
      ]
    }
  },
  computed: {
    locale () {
      return this.$i18n
    }
  },
  mounted () {
    document.documentElement.lang = this.$route?.params?.locale || 'en'
  },
  methods: {
    hide () {
      this.active = false
    },
    _activeLocale () {
      const locale = this.$i18n.locale

      for (const i in this.locales) {
        if (this.locales[i].code === locale) {
          return this.locales[i].name
        }
      }
    },
    async setLocale (locale) {
      this.$store.commit('PRELOADER_GLOBAL_EDIT', true)
      // this.$menu.down()

      if (this.$route.query.redirect) {
        const path = this.$route.query.redirect.slice(3, this.$route.query.redirect.length)
        this.$route.query.redirect = `/${locale}${path}`
      }

      this.$router.push({
        name: this.$route.name,
        params: Object.assign({}, this.$route.params, { locale: locale }),
        query: this.$route.query
      })

      if (this.$store.getters.isAuth) {
        try {
          await this.$axios.post(
            '/private/profile/change-language')
        } catch (err) {
          throw new Error(err)
        } finally {
          this.$store.commit('PRELOADER_GLOBAL_EDIT', false)
          document.documentElement.lang = this.$route.params.locale

          if (process.env.NODE_ENV === 'production') {
            window.HelpCrunch('setPhraseList', this.$route.params.locale)
          }
        }
      }

      this.$store.commit('PRELOADER_GLOBAL_EDIT', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.locale {
  position: relative;

  &.white {
    button.locale__button {
      background: white;
      color: rgb(32, 37, 57);

      svg.icon path {
        fill: rgb(32, 37, 57);
      }

      svg.arrow-icon {
        path {
          stroke: rgb(32, 37, 57);
        }
      }
    }
  }

  button.locale__button {
    height: 36px;
    padding: 5px 10px 5px 13px;
    display: flex;
    align-items: center;
    background: transparent;
    border-radius: 20px;
    border: 1px solid transparent;
    color: rgba(120, 124, 141, 1);
    font-size: 16px;
    font-family: Medium;
    transition: background .2s;
    transition: color .2s;

    &.active {
      background: white;
      border: 1px solid #2F57E9;
      color: rgb(32, 37, 57);

      svg.icon path {
        fill: rgb(32, 37, 57);
      }

      svg.arrow-icon {
        transform: rotate(180deg);

        path {
          stroke: rgb(32, 37, 57);
        }
      }
    }

    svg {
      path {
        transition: .2s;
      }

      &.icon {
        margin-right: 4px;
      }

      &.arrow-icon {
        margin-left: 7px;
        transition: .2s;
      }
    }

    &:hover {
      background: white;
      color: rgb(32, 37, 57);

      svg.icon path {
        fill: rgb(32, 37, 57);
      }

      svg.arrow-icon path {
        stroke: rgb(32, 37, 57);
      }
    }
  }

  .locale__list {
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    max-width: 90px;
    padding: 2px;
    background: white;
    border-radius: 17px;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);

    .locale__list__item {
      height: 30px;
      width: 100%;
      padding: 0 8px;
      border-radius: 20px;
      text-align: left;
      color: rgb(32, 37, 57);
      font-size: 16px;
      line-height: 100%;
      font-family: Medium;
      transition: .2s;

      &:hover {
        color: rgb(47, 87, 233);
      }

      &.active {
        background: #F0F2F8;
        color: rgb(47, 87, 233);
      }
    }
  }
}
</style>
