import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { globals } from '@/main.js'

const key = process.env.VUE_APP_FINGERPRINT_KEY || null

if (key) {
  const fpPromise = FingerprintJS.load({
    apiKey: process.env.VUE_APP_FINGERPRINT_KEY
  })

  fpPromise
    .then(fp => fp.get())
    .then(result => {
      globals.$store.commit('USER_SET_FINGERPRINT', result.visitorId)
    })
}
