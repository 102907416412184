import { globals } from '../main'

export default {
  state: {
    active: null
  },
  mutations: {
    POP_SET_UP: (state, payload) => {
      state.active = payload
    },
    POP_SET_DOWN: (state) => {
      if (state.active?.data?.length >= 1 && state.active?.name === 'universal') {
        globals.$store.dispatch('viewUniversalPopUp')
        state.active.data.shift()
      } else {
        state.active = null
      }
    }
  },
  actions: {
    viewUniversalPopUp ({ state }) {
      const id = state.active.data[0].id

      try {
        globals.$axios.post(
          `/private/account-popups/${id}`)
      } catch (err) {
        throw new Error(err)
      }
    }
  }
}
