/*
  A plugin that is used to create a global function.
*/
export default {
  install: ({ config: { globalProperties } }) => {
    const { $store } = globalProperties

    globalProperties.$pop = {
      up: (options) => {
        $store.commit('POP_SET_UP', options)
      },
      down: (options) => {
        $store.commit('POP_SET_DOWN')
      }
    }
  }
}
