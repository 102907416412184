export default {
  state: {
    global: false
  },
  mutations: {
    PRELOADER_GLOBAL_EDIT: (state, payload) => {
      if (payload) {
        state.global = payload
      } else {
        setTimeout(() => {
          state.global = payload
        }, 1000)
      }
    }
  }
}
