/* eslint-disable no-undef */

if (process.env.NODE_ENV === 'production') {
  (function (w, d) {
    w.HelpCrunch = function () { w.HelpCrunch.q.push(arguments) }; w.HelpCrunch.q = []
    function r () { var s = document.createElement('script'); s.async = 1; s.type = 'text/javascript'; s.src = 'https://widget.helpcrunch.com/'; (d.body || d.head).appendChild(s) }
    if (w.attachEvent) { w.attachEvent('onload', r) } else { w.addEventListener('load', r, false) }
  })(window, document)

  HelpCrunch('init', 'onlix', {
    applicationId: 2,
    applicationSecret: 'C2fckpnSM/PO4g36NOxuaO2st4z9/JMKxSi16MwoEV19X4sEwXhb0cboWNfx/zdDEVzuvnfclCWstoLqERMQRg==',
    user: {
      email: '',
      name: '',
      user_id: ''
    }
  })

  HelpCrunch('showChatWidget')
}
