import authRoute from './auth'

const routes = [
  {
    path: '/:locale',
    component: () => import('../../layouts/Init.vue'),
    children: [
      authRoute
    ]
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () => import('../../layouts/Error.vue')
  }
]

export default routes
