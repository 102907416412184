import { createStore } from 'vuex'

import preloader from './preloader'
import popUp from './popUp'
import window from './window'
import error from './error'
import user from './user'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    preloader,
    popUp,
    window,
    error,
    user
  }
})
