<template>
  <header
    class="header"
    :class="{
      auth: simple
    }"
  >
    <div class="header__left">
      <div
        v-if="simple"
        class="header__left__logo"
      >
        <RouterLink
          v-if="$route.path.indexOf('/payment/') !== -1"
          :to="{ name: 'Main' }"
        >
          <img src="@/static/svg/authorization-logo.svg">
        </RouterLink>

        <a
          v-else
          :href="_getSiteLink"
        >
          <img src="@/static/svg/authorization-logo.svg">
        </a>
      </div>

      <Locale />
    </div>
  </header>
</template>

<script>
import Locale from '@/ui/buttons/Locale.vue'

export default {
  components: {
    Locale
  },
  props: {
    simple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isProfile: false
    }
  },
  computed: {
    _getSiteLink () {
      return `https://demolix.org/${this.$i18n.locale === 'en' ? '' : `/${this.$i18n.locale}`}`
    }
  }
}
</script>

<style lang="scss" scoped>
header.header {
  position: relative;
  height: 80px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid  rgba(156, 163, 184, .6);
  z-index: 10;

  .header__left {
    display: flex;
    align-items: center;

    .header__left__logo {
      height: 60px;
      margin-right: 30px;
      padding-right: 140px;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(156, 163, 184, .6);
    }
  }
}

@media screen and (max-width: 768px) {
  header.header {
    height: 60px;
    width: 100%;
    padding: 0 10px;

    &.auth {
      .header__left {
        display: flex;
      }
    }

    .header__left {
      width: 100%;
      display: none;

      .header__left__logo {
        height: 50px;
        width: 100%;
        padding-right: 0;
      }
    }
  }
}
</style>
