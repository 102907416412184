import { RouterView } from 'vue-router'

const routes = {
  name: 'Authorization',
  path: '',
  component: () => import('../../layouts/Auth.vue'),
  redirect: { name: 'SignIn' },
  children: [
    {
      name: 'SignIn',
      path: 'sign-in',
      component: () => import('../../pages/SignIn.vue')
    },
    {
      name: 'ForgotPassword',
      path: 'forgot-password',
      component: () => import('../../pages/ForgotPassword.vue')
    },
    {
      name: 'Rules',
      path: 'rules-of-participation',
      component: () => import('../../pages/Rules.vue')
    },
    {
      path: 'sign-up',
      component: RouterView,
      children: [
        {
          name: 'SignUp',
          path: '',
          component: () => import('../../pages/sign-up/Index.vue')
        },
        {
          name: 'SignUpConfirm',
          path: 'confirm',
          component: () => import('../../pages/sign-up/Confirm.vue')
        },
        {
          name: 'SignUpInfo',
          path: 'info',
          component: () => import('../../pages/sign-up/Info.vue')
        },
        {
          path: 'verify/:code?',
          component: () => import('../../pages/sign-up/verify/Layout.vue'),
          children: [
            {
              name: 'SignUpEmailVerify',
              path: '',
              component: () => import('../../pages/sign-up/verify/Email.vue')
            },
            {
              path: 'phone/:hash',
              component: RouterView,
              children: [
                {
                  name: 'SignUpVerifyPhone',
                  path: '',
                  component: () => import('../../pages/sign-up/verify/Phone.vue')
                },
                {
                  name: 'SignUpVerifyPhoneEdit',
                  path: 'edit',
                  component: () => import('../../pages/sign-up/verify/PhoneEdit.vue')
                },
                {
                  name: 'SignUpVerifyPhoneCode',
                  path: 'verify',
                  component: () => import('../../pages/sign-up/verify/PhoneVerify.vue')
                },
                {
                  name: 'SignUpVerifyPhoneCodeResend',
                  path: 'resend',
                  component: () => import('../../pages/sign-up/verify/PhoneVerifyResend.vue')
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'RecoveryPassword',
      path: 'password/recovery/:code',
      component: () => import('../../pages/RecoveryPassword.vue')
    }
  ]
}

export default routes
