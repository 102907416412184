<template>
  <div
    class="view"
    id="view"
  >
    <RouterView v-if="!_getError" />

    <Error v-else />
  </div>

  <Transition>
    <PopUp v-if="$store.state.popUp.active" />
  </Transition>

  <Transition>
    <Preloader v-if="$store.state.preloader.global" />
  </Transition>
</template>

<script>
import Error from '@/layouts/Error.vue'
import PopUp from '@/modules/app/pop-up/Container.vue'
import Preloader from '@/ui/preloader/GlobalAnimatePreloader.vue'

export default {
  components: { Error, PopUp, Preloader },
  computed: {
    _getError () {
      return !!this.$store.state.error.data
    }
  },
  watch: {
    '$route.path' () {
      const elem = document.querySelector('#view')
      elem.scrollTo(0, 0)
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/main';

.view {
  max-height: 100vh;
  width: 100%;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    max-height: none !important;
  }
}

@media screen and (max-width: 768px) {
  body {
    min-height: auto !important;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity .5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
