import axios from 'axios'

/* Creating a new axios instance with a baseURL of process.env.VUE_API. */
const axiosInstance = axios.create({
  baseURL: 'https://backend.demolix.org/api/v1'
})

export default {
  install: ({ config: { globalProperties } }) => {
    globalProperties.$axios = { ...axiosInstance }
  }
}
