export default {
  state: {
    fingerprint: null
  },
  mutations: {
    USER_SET_FINGERPRINT: (state, payload) => {
      state.fingerprint = payload
    }
  }
}
