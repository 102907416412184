<template>
  <div class="container-page">
    <img
      class="background"
      src="@/static/svg/error-background-gradient.svg"
    >

    <div class="error-header">
      <Header simple />
    </div>

    <div class="content">
      <h1>
        404
      </h1>

      <h3>
        {{ $t('views.error.title') }}

        <br>

        {{ $t('views.error.text') }}
      </h3>

      <p>
        {{ $t('views.error.description') }}
      </p>

      <BlueButton
        :text="$t('views.error.button')"
        tag="a"
        :href="`/${$i18n.locale}`"
      />
    </div>

    <div class="image">
      <img
        src="@/static/svg/error-image.svg"
      />
    </div>
  </div>
</template>

<script>
import Header from '@/modules/app/Header.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    Header,
    BlueButton
  },
  beforeUnmount () {
    this.$store.commit('SET_ERROR', null)
  }
}
</script>

<style lang="scss" scoped>
.container-page {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 20px 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    padding-top: 120px;
    padding-bottom: 120px;
    flex-direction: column-reverse;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  img.background {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .error-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    padding: 0 10px;
    z-index: 5;
  }

  .content {
    position: relative;
    width: 100%;
    max-width: 470px;
    margin: 0 auto;
    z-index: 2;
    overflow: hidden;

    @media screen and (max-width: 900px) {
      max-width: 100%;
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }

    h1 {
      font-size: 90px;
      line-height: 100%;
      color: #202539;
      font-family: Medium;

      @media screen and (max-width: 768px) {
        font-size: 60px;
        line-height: 72px;
      }
    }

    h3 {
      font-size: 36px;
      line-height: 43px;
      color: #202539;
      font-family: Medium;

      @media screen and (max-width: 768px) {
        font-size: 26px;
        line-height: 31px;
      }
    }

    p {
      margin: 20px 0 70px;
      font-size: 16px;
      line-height: 150%;
      color: #787C8D;

      @media screen and (max-width: 768px) {
        margin: 15px 0 30px;
      }
    }

    button {
      max-width: 270px;

      @media screen and (max-width: 768px) {
        max-width: 270px;
      }

      @media screen and (max-width: 430px) {
        max-width: 100%;
      }
    }
  }

  .image {
    position: relative;
    margin-left: 30px;
    z-index: 2;

    @media screen and (max-width: 900px) {
      width: calc(100vw / 2.5);
      min-width: 200px;
      margin: 0;

      img {
        width: 100%;
      }
    }
  }
}
</style>
