<template>
  <div
    class="pop-up"
    :class="{
      'to-left': visible === 'containerInfo'
    }"
  >
    <div class="pop-up__flex">
      <Component
        :is="visible"
        :key="$store.state.popUp.active"
        class="shadow"
        v-click-outside="hide"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    successfully: defineAsyncComponent(() => import('./SuccessfullyPopUp.vue')),
    universal: defineAsyncComponent(() => import('./UniversalPopUp.vue')),
    presentations: defineAsyncComponent(() => import('./PresentationPopUp.vue')),
    emailPassword: defineAsyncComponent(() => import('./EmailPasswordPopUp.vue'))
  },
  data () {
    return {
      visible: null
    }
  },
  mounted () {
    document.addEventListener('keydown', this.keydown)

    const width = window.innerWidth
    if (width <= 768) {
      const body = document.body
      body.style.overflow = 'hidden'
    }
  },
  beforeUnmount () {
    document.removeEventListener('keydown', this.keydown)
    const body = document.body
    body.style.overflow = 'auto'
  },
  watch: {
    '$route' () {
      this.$pop.down()
    },
    '$store.state.popUp.active': {
      handler () {
        const popUp = this.$store.state.popUp.active
        var parseVal = null

        if (typeof popUp === 'string') {
          parseVal = popUp
        } else if (typeof popUp === 'object') {
          parseVal = popUp?.name ? popUp?.name : null
        }

        this.visible = parseVal
      },
      immediate: true
    }
  },
  methods: {
    hide (e) {
      if (window.innerWidth > 768) {
        this.$pop.down()
      }
    },
    keydown (e) {
      if (e.code === 'Escape') {
        this.$pop.down()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-up {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 20px 17px;
  background: rgba(14, 14, 27, .4);
  overflow-y: auto;
  z-index: 19999000;

  &.to-left {
    padding: 0 0 0 20px;
    justify-content: flex-end;

    .pop-up__flex {
      height: 100%;
      justify-content: flex-end;
    }
  }

  .pop-up__flex {
    height: auto;
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .shadow {
      box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
    }
  }
}

@media screen and (max-width: 768px) {
  .pop-up {
    padding: 17px;
    align-items: flex-start;
    overflow-y: auto;

    &.to-left {
      padding: 80px 0 0 0 !important;
      align-items: flex-end !important;
    }
  }
}
</style>
