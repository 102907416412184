export default {
  state: {
    data: null
  },
  mutations: {
    SET_ERROR: (state, payload) => {
      state.data = payload
    }
  }
}
