/* eslint-disable no-unused-expressions */
/* eslint-disable import/first */
import { createApp } from 'vue'
import App from './App.vue'

if (process.env.NODE_ENV === 'production') {
  import('./plugins/metrics/gtm')
}

import store from './store'
import router from './router'
import i18n from './config/i18n'

// Import global function
import auth from './plugins/globals/auth'
import axios from './plugins/globals/axios'
import popUp from './plugins/globals/pop-up'
import format from './plugins/globals/format'
import gtm from './plugins/globals/gtm'

// Import directives
import vClickOutside from 'click-outside-vue3'

import './plugins/window'
import './plugins/help-crunch'
import './plugins/fingerprint'

// Init App
const app = createApp(App)

// Use plugins
app
  .use(store)
  .use(i18n)
  .use(router)
  .use(axios)
  .use(auth)
  .use(gtm)
  .use(popUp)
  .use(format)
  .use(window)

  .use(vClickOutside)

// Start app
app.mount('#app')

// Export global properties
const globals = app.config.globalProperties
export { globals }
