import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../config/i18n'
import routes from './routes/index'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const pathLocale = to.path.split('/')[1] || ''

  if (pathLocale.length === 2 && !i18n.global.availableLocales.includes(pathLocale)) {
    return next(`en${to.fullPath.slice(3, to.fullPath.length)}`)
  } else if (to.path.length === 1) {
    return next(`en${to.fullPath}`)
  } else if (pathLocale.length > 2) {
    return next(`en${to.fullPath}`)
  } else {
    if (!to.params.locale) {
      to.params.locale = pathLocale
    }

    return next()
  }
})

export default router
